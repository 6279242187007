import { default as _91slug_938i2NJgN4GvMeta } from "/usr/src/app/pages/[country]/[slug].vue?macro=true";
import { default as index7keTxj1PUVMeta } from "/usr/src/app/pages/[country]/about-us/index.vue?macro=true";
import { default as bagksyWaGh5ihMeta } from "/usr/src/app/pages/[country]/bag.vue?macro=true";
import { default as indexMo43axeGVFMeta } from "/usr/src/app/pages/[country]/checkout/[code]/easy-pay-result/index.vue?macro=true";
import { default as index8RBFfDxPwyMeta } from "/usr/src/app/pages/[country]/checkout/[code]/index.vue?macro=true";
import { default as _91status_93zQbzKVFdqCMeta } from "/usr/src/app/pages/[country]/checkout/[code]/payment-result/[status].vue?macro=true";
import { default as index8dcmj2pMNQMeta } from "/usr/src/app/pages/[country]/checkout/[code]/payubiz-result/index.vue?macro=true";
import { default as resultf6q8dHVSWIMeta } from "/usr/src/app/pages/[country]/checkout/[code]/result.vue?macro=true";
import { default as visa_45net_45resultq2zSq7mOCtMeta } from "/usr/src/app/pages/[country]/checkout/[code]/visa-net-result.vue?macro=true";
import { default as contactCWf4xtH4qnMeta } from "/usr/src/app/pages/[country]/contact.vue?macro=true";
import { default as indexiC2XA9kvitMeta } from "/usr/src/app/pages/[country]/index.vue?macro=true";
import { default as _tokenS3PFiZdyZHMeta } from "/usr/src/app/pages/[country]/invitation/_token.vue?macro=true";
import { default as index1eQN3vFTuyMeta } from "/usr/src/app/pages/[country]/my-account/addresses/index.vue?macro=true";
import { default as indexjOLwuCpDGTMeta } from "/usr/src/app/pages/[country]/my-account/bikes/index.vue?macro=true";
import { default as recoverykwcrxYnRONMeta } from "/usr/src/app/pages/[country]/my-account/bikes/recovery.vue?macro=true";
import { default as returnR80s9rr9gVMeta } from "/usr/src/app/pages/[country]/my-account/bikes/return.vue?macro=true";
import { default as transfersjq3itXFt4Meta } from "/usr/src/app/pages/[country]/my-account/bikes/transfer.vue?macro=true";
import { default as warrantykr00Nt51hqMeta } from "/usr/src/app/pages/[country]/my-account/bikes/warranty.vue?macro=true";
import { default as indexC7On3iwKIYMeta } from "/usr/src/app/pages/[country]/my-account/index.vue?macro=true";
import { default as indexyaasEgs1DPMeta } from "/usr/src/app/pages/[country]/my-account/orders/_code/index.vue?macro=true";
import { default as indexovZMFqsrpwMeta } from "/usr/src/app/pages/[country]/my-account/orders/index.vue?macro=true";
import { default as _91slug_id_93rTeuqZVpP4Meta } from "/usr/src/app/pages/[country]/news/[slug_id].vue?macro=true";
import { default as indexx5KTTyMZ6bMeta } from "/usr/src/app/pages/[country]/news/index.vue?macro=true";
import { default as _91slug_930u3Pwi1NwQMeta } from "/usr/src/app/pages/[country]/products/[slug].vue?macro=true";
import { default as resendAUSRXZWAhTMeta } from "/usr/src/app/pages/[country]/resend.vue?macro=true";
import { default as _91type_93w52rTHu4oXMeta } from "/usr/src/app/pages/[country]/search/[type].vue?macro=true";
import { default as indexmySwA5trigMeta } from "/usr/src/app/pages/[country]/search/index.vue?macro=true";
import { default as stores2ptQ6k0P4fMeta } from "/usr/src/app/pages/[country]/stores.vue?macro=true";
import { default as indexn8lgecHbtmMeta } from "/usr/src/app/pages/[country]/subscriber-verify/index.vue?macro=true";
import { default as test_45drive6jsIU5PiWIMeta } from "/usr/src/app/pages/[country]/test-drive.vue?macro=true";
import { default as indexGM3QJXuJIUMeta } from "/usr/src/app/pages/[country]/verify/index.vue?macro=true";
import { default as warrantyvi90ACRfzWMeta } from "/usr/src/app/pages/[country]/warranty.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
export default [
  {
    name: "country-slug",
    path: "/:country()/:slug()",
    component: () => import("/usr/src/app/pages/[country]/[slug].vue")
  },
  {
    name: "country-about-us",
    path: "/:country()/about-us",
    component: () => import("/usr/src/app/pages/[country]/about-us/index.vue")
  },
  {
    name: "country-bag",
    path: "/:country()/bag",
    meta: bagksyWaGh5ihMeta || {},
    component: () => import("/usr/src/app/pages/[country]/bag.vue")
  },
  {
    name: "country-checkout-code-easy-pay-result",
    path: "/:country()/checkout/:code()/easy-pay-result",
    meta: indexMo43axeGVFMeta || {},
    component: () => import("/usr/src/app/pages/[country]/checkout/[code]/easy-pay-result/index.vue")
  },
  {
    name: "country-checkout-code",
    path: "/:country()/checkout/:code()",
    meta: index8RBFfDxPwyMeta || {},
    component: () => import("/usr/src/app/pages/[country]/checkout/[code]/index.vue")
  },
  {
    name: "country-checkout-code-payment-result-status",
    path: "/:country()/checkout/:code()/payment-result/:status()",
    meta: _91status_93zQbzKVFdqCMeta || {},
    component: () => import("/usr/src/app/pages/[country]/checkout/[code]/payment-result/[status].vue")
  },
  {
    name: "country-checkout-code-payubiz-result",
    path: "/:country()/checkout/:code()/payubiz-result",
    meta: index8dcmj2pMNQMeta || {},
    component: () => import("/usr/src/app/pages/[country]/checkout/[code]/payubiz-result/index.vue")
  },
  {
    name: "country-checkout-code-result",
    path: "/:country()/checkout/:code()/result",
    meta: resultf6q8dHVSWIMeta || {},
    component: () => import("/usr/src/app/pages/[country]/checkout/[code]/result.vue")
  },
  {
    name: "country-checkout-code-visa-net-result",
    path: "/:country()/checkout/:code()/visa-net-result",
    meta: visa_45net_45resultq2zSq7mOCtMeta || {},
    component: () => import("/usr/src/app/pages/[country]/checkout/[code]/visa-net-result.vue")
  },
  {
    name: "country-contact",
    path: "/:country()/contact",
    component: () => import("/usr/src/app/pages/[country]/contact.vue")
  },
  {
    name: "country",
    path: "/:country()",
    component: () => import("/usr/src/app/pages/[country]/index.vue")
  },
  {
    name: "country-invitation-_token",
    path: "/:country()/invitation/_token",
    component: () => import("/usr/src/app/pages/[country]/invitation/_token.vue")
  },
  {
    name: "country-my-account-addresses",
    path: "/:country()/my-account/addresses",
    meta: index1eQN3vFTuyMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/addresses/index.vue")
  },
  {
    name: "country-my-account-bikes",
    path: "/:country()/my-account/bikes",
    meta: indexjOLwuCpDGTMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/bikes/index.vue")
  },
  {
    name: "country-my-account-bikes-recovery",
    path: "/:country()/my-account/bikes/recovery",
    component: () => import("/usr/src/app/pages/[country]/my-account/bikes/recovery.vue")
  },
  {
    name: "country-my-account-bikes-return",
    path: "/:country()/my-account/bikes/return",
    component: () => import("/usr/src/app/pages/[country]/my-account/bikes/return.vue")
  },
  {
    name: "country-my-account-bikes-transfer",
    path: "/:country()/my-account/bikes/transfer",
    component: () => import("/usr/src/app/pages/[country]/my-account/bikes/transfer.vue")
  },
  {
    name: "country-my-account-bikes-warranty",
    path: "/:country()/my-account/bikes/warranty",
    component: () => import("/usr/src/app/pages/[country]/my-account/bikes/warranty.vue")
  },
  {
    name: "country-my-account",
    path: "/:country()/my-account",
    meta: indexC7On3iwKIYMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/index.vue")
  },
  {
    name: "country-my-account-orders-_code",
    path: "/:country()/my-account/orders/_code",
    meta: indexyaasEgs1DPMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/orders/_code/index.vue")
  },
  {
    name: "country-my-account-orders",
    path: "/:country()/my-account/orders",
    meta: indexovZMFqsrpwMeta || {},
    component: () => import("/usr/src/app/pages/[country]/my-account/orders/index.vue")
  },
  {
    name: "country-news-slug_id",
    path: "/:country()/news/:slug_id()",
    component: () => import("/usr/src/app/pages/[country]/news/[slug_id].vue")
  },
  {
    name: "country-news",
    path: "/:country()/news",
    component: () => import("/usr/src/app/pages/[country]/news/index.vue")
  },
  {
    name: "country-products-slug",
    path: "/:country()/products/:slug()",
    component: () => import("/usr/src/app/pages/[country]/products/[slug].vue")
  },
  {
    name: "country-resend",
    path: "/:country()/resend",
    meta: resendAUSRXZWAhTMeta || {},
    component: () => import("/usr/src/app/pages/[country]/resend.vue")
  },
  {
    name: "country-search-type",
    path: "/:country()/search/:type()",
    component: () => import("/usr/src/app/pages/[country]/search/[type].vue")
  },
  {
    name: "country-search",
    path: "/:country()/search",
    component: () => import("/usr/src/app/pages/[country]/search/index.vue")
  },
  {
    name: "country-stores",
    path: "/:country()/stores",
    component: () => import("/usr/src/app/pages/[country]/stores.vue")
  },
  {
    name: "country-subscriber-verify",
    path: "/:country()/subscriber-verify",
    component: () => import("/usr/src/app/pages/[country]/subscriber-verify/index.vue")
  },
  {
    name: "country-test-drive",
    path: "/:country()/test-drive",
    component: () => import("/usr/src/app/pages/[country]/test-drive.vue")
  },
  {
    name: "country-verify",
    path: "/:country()/verify",
    component: () => import("/usr/src/app/pages/[country]/verify/index.vue")
  },
  {
    name: "country-warranty",
    path: "/:country()/warranty",
    component: () => import("/usr/src/app/pages/[country]/warranty.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue")
  }
]